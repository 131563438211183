/**
 * Menu controller. Controls the menu.
 */

import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.documentElement = document.querySelector('html');
    this.contentElement = document.querySelector('.main-content');
    this.sidebarElement = document.querySelector('.sidebar');

    // Select all the elements that use a specified distinguisher.
    const menuTriggers = document.querySelectorAll('[data-menu-toggle]');

    // For each element, add a listener for the "click" event.
    Array.prototype.forEach.apply(menuTriggers, [
      trigger => {
        trigger.addEventListener('click', event => {
          // Prevent default link behaviour
          event.preventDefault();

          // Toggle the sidebar when a click is detected.
          this.toggleSidebar();
        });
      },
    ]);
  }

  sidebarIsOpen() {
    return this.documentElement.classList.contains('sidebar-is-open');
  }

  openSidebar() {
    // The crux of the idea is to add some attribute/class that triggers the
    // visual change, you don't necessarily only have to add one class.
    this.documentElement.classList.add('sidebar-is-open');

    // Tapping beyond the sidebar should close it.
    this.contentElement.addEventListener(
      'click',
      this.handleClickBeyondSidebar,
    );
  }

  closeSidebar() {
    this.documentElement.classList.remove('sidebar-is-open');

    // Sidebar is closed, so keeping event listener is just a waste of resources
    // and source of bugs if openSidebar() is run again.
    this.contentElement.removeEventListener('click');
  }

  handleClickBeyondSidebar(event) {
    // This implementation assumes that all clicks beyond the sidebar close it.
    // Your sidebar might open modals and other elements not within the sidebar,
    // in which case you'd have to make this more precise!
    if (!this.sidebarElement.contains(event.target)) {
      this.closeSidebar();
    }
  }

  toggleSidebar() {
    return this.sidebarIsOpen() ? this.closeSidebar() : this.openSidebar();
  }
}
