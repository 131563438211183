// Pricing controller.

import { Controller } from 'stimulus';
import { createAndSubmitForm } from '../helpers/form_helper';

export default class extends Controller {
  static targets = [
    'slider',
    'sliderValue',
    'price',
    'pricePrefix',
    'priceSuffix',
    'sliderHelp',
    'signupButton',
    'contactButton',
    'signupPeriodHelp',
    'signupPeriodToggle',
    'overlay',
  ];

  // Our list of plans.
  plans = {};

  // The selected plan.
  selectedPlan = null;

  // The selected billing period (monthly/yearly).
  selectedBillingPeriod = 'monthly';

  // Our Stripe Checkout handler.
  stripeCheckoutHandler = null;

  connect() {
    // Load our plans.
    this.plans = JSON.parse(
      document.querySelector(
        'script[type="application/json"][data-iplocate-object="planData"]',
      ).textContent,
    );

    // Update.
    this.updateSuggestedPlan();

    // Configure Stripe checkout.
    this.configureStripeCheckout();
  }

  updateSuggestedPlan(_event) {
    let requestsPerMonth = parseInt(this.sliderTarget.value, 10);

    // Update the number of requests text.
    this.sliderValueTarget.textContent = requestsPerMonth.toLocaleString('en');

    // Update the 'requests per day' help text.
    const requestsPerDay = requestsPerMonth / 30;
    const approxRequestsPerDay = Math.round(requestsPerDay / 100) * 100;
    this.sliderHelpTarget.textContent = `(That's about ${approxRequestsPerDay.toLocaleString(
      'en',
    )} requests per day)`;

    // Is this the maximum number of requests for a self-serve plan?
    const isMax =
      this.sliderTarget.value == this.sliderTarget.getAttribute('max');
    if (isMax) {
      requestsPerMonth = parseInt(
        this.sliderTarget.getAttribute('data-max-plan'),
        10,
      );

      this.sliderValueTarget.textContent = `${requestsPerMonth.toLocaleString(
        'en',
      )}+`;
      this.sliderHelpTarget.textContent = "(That's a lot of requests per day)";

      this.priceTarget.textContent = 'Contact us';
      this.pricePrefixTarget.classList.add('hidden');
      this.priceSuffixTarget.classList.add('hidden');
      this.signupButtonTarget.classList.add('invisible', 'opacity-0');
      this.contactButtonTarget.classList.remove('invisible', 'opacity-0');
      this.signupPeriodHelpTarget.classList.add('invisible', 'opacity-0');

      return;
    }

    let suggestedPlan = null;
    for (const plan of this.plans) {
      // Continue if this plan doesn't cover our required number of requests.
      if (plan.quota < requestsPerMonth) {
        continue;
      }

      // Suggest this plan if we don't yet have one.
      if (!suggestedPlan) {
        suggestedPlan = plan;
        continue;
      }

      // Suggest this plan if it's cheaper than our current one.
      if (plan.price_per_month < suggestedPlan.price_per_month) {
        suggestedPlan = plan;
      }
    }

    // Bail if we didn't have a suggested plan, somehow.
    if (!suggestedPlan) {
      return;
    }

    this.selectedPlan = suggestedPlan;

    // Update our price, and make sure our prefix and suffix are shown.
    this.updateBillingPeriodText();
    this.pricePrefixTarget.classList.remove('hidden');
    this.priceSuffixTarget.classList.remove('hidden');
    this.signupButtonTarget.classList.remove('invisible', 'opacity-0');
    this.contactButtonTarget.classList.add('invisible', 'opacity-0');
    this.signupPeriodHelpTarget.classList.remove('invisible', 'opacity-0');
  }

  /**
   * Toggle between monthly and annual billing.
   */
  toggleBillingPeriod() {
    event.preventDefault();

    // Swap, swap.
    this.selectedBillingPeriod =
      this.selectedBillingPeriod === 'monthly' ? 'yearly' : 'monthly';

    // Update our text.
    this.updateBillingPeriodText();
  }

  /**
   * Update billing period.
   */
  updateBillingPeriodText() {
    if (this.selectedBillingPeriod === 'monthly') {
      // @todo cute thing with '2 months free!'
      this.signupPeriodToggleTarget.textContent =
        'Pay annually and get two months free';
      this.priceTarget.textContent = this.selectedPlan.price_per_month;
      this.priceSuffixTarget.textContent = '/month';
    } else {
      this.signupPeriodToggleTarget.textContent =
        'Switch back to paying monthly';
      this.priceTarget.textContent = this.selectedPlan.price_per_year;
      this.priceSuffixTarget.textContent = '/year';
    }
  }

  /**
   * Open Stripe checkout to sign up.
   *
   * @param {Event} event
   *   The triggering event.
   */
  signup(event) {
    event.preventDefault();

    if (!this.selectedPlan) {
      console.error('No plan selected.');
      console.error(this.plans);
      return;
    }

    // Create our description string.
    const description = `${this.selectedPlan.quota.toLocaleString(
      'en',
    )} requests per month`;

    // Set our amount depending on whether we're paying monthly or annually. We
    // also need to pass the amount in cents, so multiply by 100.
    const amount =
      (this.selectedBillingPeriod === 'monthly'
        ? this.selectedPlan.price_per_month
        : this.selectedPlan.price_per_year) * 100;

    // Start Stripe Checkout.
    const checkoutOptions = {
      name: 'IPLocate',
      description,
      currency: 'usd',
      amount,
      // email:           window.iplocateSettings.userEmail,
      // allowRememberMe: false,
    };

    const planId = this.selectedBillingPeriod === 'monthly'
      ? this.selectedPlan.stripe_monthly_plan_id
      : this.selectedPlan.stripe_yearly_plan_id;

    window.stripe.redirectToCheckout({
      lineItems: [{
        price: planId,
        quantity: 1
      }],
      mode: 'subscription',
      successUrl: `${window.stripe_base_url}signup-success`,
      cancelUrl: `${window.stripe_base_url}pricing`,
    });

    // // Show the loading overlay.
    // this.overlayTarget.classList.remove('invisible', 'opacity-0');

    // // Fetch our CSRF token.
    // const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    // // Configure and open Stripe checkout.
    // fetch('/signup/stripe-session', {
    //   method: 'POST',
    //   credentials: 'include',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     'X-CSRF-Token': csrfToken,
    //   },
    //   body: JSON.stringify({
    //     plan_id: this.selectedPlan.id,
    //     billing_period: this.selectedBillingPeriod,
    //   }),
    // })
    // .then(response => response.json())
    // .then(response => {
    //   stripe.redirectToCheckout({
    //     sessionId: response.session_id,
    //   });
    // })
    // .catch(error => {
    //   this.overlayTarget.classList.add('invisible', 'opacity-0');
    // });
  }

  /**
   * Handle Stripe Checkout completion.
   *
   * @param {object|null} token
   *   The token details.
   */
  handleStripeCheckoutComplete(token) {
    // Bail if we didn't get a token.
    if (!token) {
      return;
    }

    const params = {
      plan_id: this.selectedPlan.id,
      plan_frequency: this.selectedBillingPeriod,
      'stripe_token[id]': token.id,
      'stripe_token[email]': token.email,
    };

    if (typeof window.testStripeToken === 'string') {
      params['stripe_token[id]'] = window.testStripeToken;
    }

    createAndSubmitForm('/signup/', params);
  }

  /**
   * Configure Stripe Checkout.
   */
  configureStripeCheckout() {
    this.stripeCheckoutHandler = StripeCheckout.configure({
      key: window.stripe_publishable_key,
      locale: 'auto',
      token: this.handleStripeCheckoutComplete.bind(this),
    });
  }
}
