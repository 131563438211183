/**
 * Loader
 *
 * Provides loading spinner things.
 */

import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.handleClick);
  }

  /**
   * Handle clicking this button.
   */
  handleClick(event) {
    event.preventDefault();

    const element = event.currentTarget;
    console.log(element);

    // Create our loading spinner.
    const spinner = document.createElement('div');
    spinner.classList.add('spinner', 'spinner-xs', 'ml-2');

    // Ensure we have the correct classes set.
    element.classList.add('flex', 'items-center');

    // Add it.
    element.setAttribute('disabled', '');
    element.insertAdjacentElement('beforeend', spinner);
  }
}
