/**
 * @file
 * Account subscription controller.
 */

import { createAndSubmitForm } from '../helpers/form_helper';
import AccountController from './account_controller';

export default class extends AccountController {
  static targets = [
    'confirmModal',
    'confirmModalUpgrade',
    'confirmModalDowngrade',
    'confirmModalDescription',
    'confirmModalPrice',
  ];

  // The plan we're changing to.
  newPlan = null;

  // Our Stripe Checkout handler.
  stripeCheckoutHandler = null;

  connect() {
    super.connect();

    // Configure Stripe Checkout.
    this.configureStripeCheckout();
  }

  /**
   * Configure Stripe Checkout.
   */
  configureStripeCheckout() {
    this.stripeCheckoutHandler = StripeCheckout.configure({
      key: window.stripe_publishable_key,
      locale: 'auto',
      token: this.handleStripeCheckoutComplete.bind(this),
    });
  }

  updateCard(event) {
    event.preventDefault();

    // Start Stripe Checkout.
    const checkoutOptions = {
      name: 'IPLocate',
      description: 'Update your card',
      currency: 'usd',
      panelLabel: 'Update card details',
      email: this.account.email,
      allowRememberMe: false,
    };

    this.stripeCheckoutHandler.open(checkoutOptions);
  }

  /**
   * Handle Stripe Checkout completion.
   *
   * @param {object|null} token
   *   The token details.
   */
  handleStripeCheckoutComplete(token) {
    // Bail if we didn't get a token.
    if (!token) {
      return;
    }

    const params = {
      'stripe_token[id]': token.id,
    };

    createAndSubmitForm('/account/subscription/update_card/', params);
  }

  changePlan(event) {
    event.preventDefault();

    // Figure out which plan we clicked.
    const planId = event.currentTarget.getAttribute('data-plan-id');
    this.newPlan = this.plans.find(plan => plan.id == planId);

    // Figure out how much we're charging for this plan, based on whether we pay
    // monthly or annually.
    const price =
      this.account.plan_frequency === 'monthly'
        ? this.newPlan.price_per_month
        : this.newPlan.price_per_year;

    // Create our description string.
    const description = `${this.newPlan.quota.toLocaleString(
      'en',
    )} requests per month`;
    this.confirmModalDescriptionTarget.textContent = description;

    this.confirmModalPriceTarget.textContent = `${price.toLocaleString('en', {
      style: 'currency',
      currency: 'USD',
    })} per ${this.account.plan_frequency_singular}`;

    // Figure out whether this is an upgrade or downgrade, so we can show the
    // correct text.
    const isUpgrade = price > this.account.plan_price;
    this.confirmModalUpgradeTarget.classList.toggle('hidden', !isUpgrade || !this.account.has_plan);
    this.confirmModalDowngradeTarget.classList.toggle('hidden', isUpgrade || !this.account.has_plan);

    // Find and open our modal.
    const modalController = this.application.getControllerForElementAndIdentifier(
      this.confirmModalTarget,
      'modal',
    );

    modalController.open(event);
  }

  /**
   * Confirm changing our current plan.
   */
  confirmChangePlan(event) {
    event.preventDefault();

    createAndSubmitForm('/account/subscription/plan/', {
      plan_id: this.newPlan.id,
    });
  }
}
