/**
 * Form helpers.
 */

/**
 * Create and submit a <form> to make a POST request.
 *
 * @param {string} path
 *   Path to submit the form.
 * @param {object} params
 *   Form parameters to pass.
 */
export const createAndSubmitForm = (path, params) => {
  const form = document.createElement('form');
  form.method = 'post';
  form.action = path;

  // Fetch and append the Rails CSRF token, if we have one.
  const csrfToken = document.querySelector('meta[name="csrf-token"]');
  if (csrfToken) {
    params['authenticity_token'] = csrfToken.getAttribute('content');
  }

  for (const key in params) {
    if (!params.hasOwnProperty(key)) {
      continue;
    }

    const field = document.createElement('input');
    field.type = 'hidden';
    field.name = key;
    field.value = params[key];

    form.appendChild(field);
  }

  document.body.appendChild(form);
  form.submit();
}
