// Alerts controller.

import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    // Find any alerts that should slide down, and slide them down.
    document.querySelectorAll('.alert-slide-down').forEach(element => {
      element.classList.add('visible');
    });

    // Find any alerts that are dismissable, and add a click handler for them.
    document.querySelectorAll('.alert.dismissable').forEach(element => {
      element.addEventListener('click', this.dismissAlert);
    });
  }

  /**
   * Dismiss an alert.
   *
   * Animates out and then gets removed from the DOM.
   *
   * @param {MouseEvent} event
   *   The click event.
   */
  dismissAlert(event) {
    const alert = event.currentTarget;

    // If this is a slide-down alert, animate out and then remove from the DOM.
    if (alert.classList.contains('alert-slide-down')) {
      alert.addEventListener('transitionend', () => {
        alert.remove();
      });

      alert.classList.remove('visible');
    }
    // Otherwise, just remove from the DOM immediately.
    else {
      alert.remove();
    }
  }
}
