/**
 * @file
 * Account controller.
 */

import { Controller } from 'stimulus';

export default class AccountController extends Controller {
  static targets = ['apiKey'];

  // Our account data.
  account = {};

  // Our plan data.
  plans = {};

  connect() {
    // Load our plan and account data, if present.
    const accountData = document.querySelector(
      'script[type="application/json"][data-iplocate-object="accountData"]',
    );

    if (accountData) {
      this.account = JSON.parse(accountData.textContent);
    }

    const planData = document.querySelector(
      'script[type="application/json"][data-iplocate-object="planData"]',
    );

    if (planData) {
      this.plans = JSON.parse(planData.textContent);
    }
  }

  showApiKey(event) {
    event.preventDefault();

    event.target.classList.add('hidden');
    this.apiKeyTarget.classList.remove('hidden');
  }
}
