// Account controller.

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['source'];

  copy(event) {
    event.preventDefault();

    // Disable the button while we're animating, or bail if already disabled.
    const trigger = event.currentTarget || event.srcElement;

    if (trigger.hasAttribute('disabled')) {
      return;
    }
    trigger.setAttribute('disabled', '');

    // Copy it!
    const range = document.createRange();
    range.selectNode(this.sourceTarget);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();

    // Notify it!
    const blurredText = document.createElement('div');
    blurredText.textContent = this.sourceTarget.textContent;
    blurredText.classList.add('trans');

    // Grab a copy of our original source element, so we can restore it later.
    const originalSource = this.sourceTarget.cloneNode(true);

    // Replace our source element with our blurred text element.
    this.sourceTarget.innerHTML = '';
    this.sourceTarget.insertAdjacentElement('beforeend', blurredText);

    // Create our 'Copied!' text element.
    const notifyElement = document.createElement('div');
    notifyElement.textContent = 'Copied!';
    notifyElement.classList.add(
      'absolute',
      'inset-0',
      'text-center',
      'trans',
      'opacity-0',
      'font-body',
      'text-gray-900',
    );

    // Force reflow so that our transitions trigger.
    this.sourceTarget.offsetWidth; // eslint-disable-line

    // Animate!
    blurredText.classList.add('blur', 'opacity-25');

    notifyElement.classList.remove('opacity-0');

    this.sourceTarget.insertAdjacentElement('beforeend', notifyElement);

    // After a short delay, animate out our elements and re-enable the button.
    setTimeout(() => {
      blurredText.classList.remove('blur', 'opacity-25');
      notifyElement.classList.add('opacity-0', 'invisible');

      // Replace the DOM contents entirely.
      // @todo Use ontransitionend or the exact animation time.
      setTimeout(() => {
        this.sourceTarget.replaceWith(originalSource);
        trigger.removeAttribute('disabled');
      }, 250);
    }, 500);
  }
}
