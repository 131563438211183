/**
 * @file
 * Form controller.
 */

import { Controller } from 'stimulus';
import Choices from 'choices.js';
import 'choices.js/src/styles/choices.scss';
import '../css/features/choicesjs.scss';

export default class extends Controller {
  connect() {
    // Initialise Choices.js on any select elements.
    const options = {
      classNames: {
        containerOuter: 'choices',
        containerInner: 'choices__inner',
        input: 'choices__input',
        inputCloned: 'choices__input--cloned',
        list: 'choices__list',
        listItems: 'choices__list--multiple',
        listSingle: 'choices__list--single',
        listDropdown: 'choices__list--dropdown',
        item: 'choices__item',
        itemSelectable: 'choices__item--selectable',
        itemDisabled: 'choices__item--disabled',
        itemChoice: 'choices__item--choice',
        placeholder: 'choices__placeholder',
        group: 'choices__group',
        groupHeading: 'choices__heading',
        button: 'choices__button',
        activeState: 'is-active',
        focusState: 'is-focused',
        openState: 'is-open',
        disabledState: 'is-disabled',
        highlightedState: 'is-highlighted',
        hiddenState: 'is-hidden',
        flippedState: 'is-flipped',
        loadingState: 'is-loading',
        noResults: 'has-no-results',
        noChoices: 'has-no-choices',
      },
      callbackOnCreateTemplates: template => {
        return {
          item: (classNames, data) => {
            // prettier-ignore
            return template(`
              <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}" data-item data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''} ${data.disabled ? 'aria-disabled="true"' : ''}>
                <div class="choices__item-content">${data.label}</div>
              </div>
            `);
          },
          input: classNames => {
            return template(`
              <input
                type="search"
                class="${classNames.input} ${classNames.inputCloned}"
                autocomplete="off"
                autocapitalize="off"
                spellcheck="false"
                role="search"
                aria-autocomplete="list"
                >
            `);
          },
        };
      },
    };

    this.element.querySelectorAll('select').forEach(selectElement => {
      const choices = new Choices(selectElement, options);
    });
  }
}
