// Body controller.

import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    // Add a class to the body when our page has loaded.
    window.addEventListener('DOMContentLoaded', () => {
      this.element.classList.add('loaded');
    });
  }
}
