// Landing page controller.
import { Controller } from 'stimulus';

// Highlight.js.
import hljs from 'highlight.js/lib/highlight';
import json from 'highlight.js/lib/languages/json';
import '../css/features/highlightjs.scss';

hljs.registerLanguage('json', json);

export default class extends Controller {
  static targets = [
    'heroLookupInput',
    'heroLookupButton',
    'heroLookupResults',
    'heroLookupResultContainer',
    'heroLookupOverlay',
  ];

  connect() {
    // Lookup the user's IP address.
    this.lookup();

    // Initialise syntax highlighting.
    window.addEventListener('DOMContentLoaded', _event => {
      document.querySelectorAll('pre code').forEach(block => {
        hljs.highlightBlock(block);
      });
    });
  }

  lookup(event) {
    if (event) {
      event.preventDefault();
    }

    // Set overlay, and blur.
    this.setLookupOverlay(true);

    // Fetch our IP address.
    const ipAddress = this.heroLookupInputTarget.value;

    // Start the fetch.
    fetch(`/api/lookup-preview/${ipAddress}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        // Set data.
        this.heroLookupResultsTarget.innerHTML = '';
        this.heroLookupResultsTarget.insertAdjacentElement(
          'afterbegin',
          this.generateDivs(data)
        );

        this.setLookupOverlay(false);
      });
  }

  generateDivs(data, indent = 0) {
    const container = document.createElement('div');

    for (const [key, value] of Object.entries(data)) {
      // console.log(`Key: ${key}, Value: ${value}`);

      //
      const line = document.createElement('div');
      if (indent > 0) {
        line.style.paddingLeft = `${indent * 1.5}rem`;
      }

      const keyEl = document.createElement('span');
      keyEl.textContent = key;
      keyEl.classList.add('text-gray-400');
      line.appendChild(keyEl);

      const separatorEl = document.createElement('span');
      separatorEl.classList.add('text-gray-700');
      if (typeof value === 'string') {
        separatorEl.textContent = ': ';
      } else if (typeof value === 'object' && value !== null) {
        separatorEl.textContent = ': Object';
      } else if (value === null) {
        separatorEl.textContent = ': ';
      } else {
        separatorEl.textContent = ': ';
      }
      line.appendChild(separatorEl);

      if (typeof value === 'object' && value !== null) {
        container.appendChild(line);

        const valueEl = this.generateDivs(value, indent + 1);
        container.appendChild(valueEl);

        // const endEl = document.createElement('div');
        // endEl.textContent = '},';
      } else {
        let valueString = value;
        // Change some values.
        if (valueString === null) {
          valueString = 'null';
        } else if (typeof valueString === 'string') {
          valueString = `"${valueString}"`;
        }

        const valueEl = document.createElement('span');
        valueEl.textContent = valueString;

        if (typeof value === 'string') {
          valueEl.classList.add('text-yellow-400');
        } else if (typeof value === 'number') {
          valueEl.classList.add('text-blue-400');
        } else if (value === true || value === false) {
          valueEl.classList.add('text-purple-400');
        } else if (value === null) {
          valueEl.classList.add('text-gray-600');
        } else {
          valueEl.classList.add('text-gray-600');
        }

        line.appendChild(valueEl);

        const endEl = document.createElement('span');
        endEl.classList.add('text-gray-700');
        if (typeof value === 'string') {
          endEl.textContent = ',';
        } else {
          endEl.textContent = ',';
        }
        line.appendChild(endEl);

        container.appendChild(line);
      }

    }
    return container;
  }

  setLookupOverlay(isLoading) {
    if (isLoading) {
      this.heroLookupOverlayTarget.classList.remove('opacity-0');
      this.heroLookupOverlayTarget.classList.add('opacity-100');
      this.heroLookupResultsTarget.classList.add('blur');
    } else {
      this.heroLookupOverlayTarget.classList.add('opacity-0');
      this.heroLookupOverlayTarget.classList.remove('opacity-100');
      this.heroLookupResultsTarget.classList.remove('blur');
    }
  }
}
