// Modal controller.

import { Controller } from 'stimulus';

export default class extends Controller {
  // The current modal state.
  isOpen = false;

  connect() {
    // Add our backdrop, if we don't have one yet.
    if (document.querySelector('[data-iplocate-object="modal-backdrop"]')) {
      return;
    }

    // Add a click handler for clicking on the backdrop to close the modal.
    this.element.addEventListener('click', this.handleClick.bind(this));

    // Add a keypress handler for pressing 'Esc' to close the modal.
    window.addEventListener('keydown', this.handleKeypress.bind(this));
  }

  /**
   * Open the modal.
   *
   * @param {MouseEvent} event
   *   The triggering event.
   */
  open(event) {
    event.preventDefault();

    this.element.classList.add('modal-visible');
    this.isOpen = true;
  }

  /**
   * Close the modal.
   */
  close(event) {
    event.preventDefault();

    this.element.classList.remove('modal-visible');
    this.isOpen = false;
  }

  /**
   * Handle a click on the modal.
   *
   * @param {MouseEvent} event
   *   The triggering event.
   */
  handleClick(event) {
    // We only care about clicks on the backdrop.
    const element = event.target;
    if (element !== this.element) {
      return;
    }

    this.close(event);
  }

  /**
   * Handle a keypress.
   *
   * Used to dismiss the modal when the 'esc' key is pressed.
   *
   * @param {MouseEvent} event
   *   The triggering event.
   */
  handleKeypress(event) {
    let isEscape = false;
    if ('key' in event) {
      isEscape = event.key === 'Escape' || event.key === 'Esc';
    } else {
      isEscape = event.keyCode === 27;
    }

    if (isEscape) {
      this.close(event);
    }
  }
}
